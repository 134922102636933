/*Form*/

.form {
	display: flex;
	flex-flow: column;
	/*border-radius: 12px;*/
	background-color: #171717;
	/*min-width: 400px;*/
	/*width: fit-content;*/
	width: 100%;
	height: 100%;
}

.form__content {
	padding: 20px;
	padding-bottom: 0;
}

.form__title {
	font-size: 24px;
	width: 100%;
	text-align: center;
	margin-bottom: 30px;
	color: var(--primary-text-color);
}

.form__buttons {
	display: flex;
	justify-content: flex-end;
	/*background-color: #1A1A1A;*/
	border-radius: 0 0 15px 15px;
	padding: 10px 20px;
}

.form__buttons .button {
	margin-right: 10px;
}

.form__buttons .button:last-child {
	margin-right: 0;
}

/*Label*/

.label {
	color: var(--secondary-text-color);
	font-size: 16px;
	margin-bottom: 5px;
	width: 100%;
	text-align: left;
}

/*Input*/

.input {
	display: flex;
	align-items: center;
	border-radius: 7px;
	border: solid 1px #bdbdbd;
	background-color: #f4f6f8;
	margin-bottom: 4px;
	font-size: 22px;
	width: 100%;
	height: 34px;
	box-sizing: border-box;
}

.input.fit-content {
	width: fit-content;
}

.input.no-margin {
	margin-bottom: 0;
}

.input.focus {
	border: solid 2px var(--blue-dark);
	background-color: white;
	box-sizing: content-box;
}

.input.focus input {
	background-color: white;
	color: var(--primary-text-color);
}

.input.focus i {
	color: #444444;
}

.input.compact input {
	padding: 3px;
}

.input i {
	display: flex;
	align-items: center;
	color: #999999;
	font-size: 20px;
	margin: 0 5px;
}

.input input {
	border: none;
	outline: none;
	color: #999999;
	background-color: #f4f6f8;
	width: 100%;
	filter: none;
	font-size: 16px;
	border-radius: 12px;
	padding: 0 6px;
}

/*.input input:-webkit-autofill,
.input input:-webkit-autofill:hover,
.input input:-webkit-autofill:focus,
.input input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px var(--foreground) inset !important;
	-webkit-box-shadow: 0 0 0 30px #1e1e1e inset !important;
	-webkit-text-fill-color: white;
}*/

.input__buttons {
	display: flex;
	flex-flow: row;
}

.input_error {
	font-size: 16px;
	color: var(--red-dark);
	width: 100%;
	min-height: 21px;
	text-align: left;
	margin-bottom: 10px;
}

/*Textarea*/

.textarea {
	display: flex;
	align-items: center;
	border-radius: 12px;
	border: 1px solid #e0e0e0;
	margin-bottom: 15px;
	font-size: 22px;
	width: 100%;
	box-sizing: border-box;
	padding: 5px;
}

.textarea textarea {
	border: none;
	outline: none;
	font-family: Barlow;
}

.textarea.focus {
	border: solid 1px var(--main-color);
}

/*Checkbox*/

.checkbox {
	display: flex;
	align-items: center;
}

.checkbox__icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border: 1px solid black;
	border-radius: 3px;
	margin-right: 5px;
	cursor: pointer;
}

.checkbox.checked .checkbox__icon-wrapper {
	background-color: var(--main-color);
	border: none;
	height: 22px;
	width: 22px;
}

.checkbox__icon-wrapper i {
	font-size: 16px;
	color: white;
}

.checkbox__text {
	margin-bottom: 3px;
}

/*Buttons*/

.buttons {
	display: flex;
	width: 100%;
	gap: 10px;
	color: var(--primary-text-color);
	margin-bottom: 10px;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 2px 10px;
	height: 30px;
	border-radius: 5px;
	background-color: var(--blue);
	border: solid 2px var(--blue-dark);
	color: white;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 25%);
	cursor: pointer;
}

.button.disabled {
	filter: opacity(0.5);
	cursor: auto;
}

.button.icon {
	display: flex;
	grid-template-columns: 35px auto;
	align-items: center;
}

.button i {
	display: flex;
	justify-content: center;
	font-size: 22px;
}

.button__title {
	font-size: 20px;
	padding-bottom: 4px;
	overflow: hidden;
	white-space: nowrap;
}

.button.ghost {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	border: none;
	padding: 0;
	box-shadow: none;
	background-color: transparent !important;
}

.button.compact {
	background-color: #f5f5f5;
	border: none;
	width: 34px;
	height: 34px;
	padding: 0;
	color: black;
}

.button.small {
	padding: 5px 6px;
	border-radius: 8px;
}

.button.small i {
	font-size: 16px;
}

/*Select*/

.select {
	display: flex;
	margin-bottom: 15px;
}

.select__item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border-radius: 5px;
	border: solid 1px #bdbdbd;
	margin-right: 10px;
	color: white;
	cursor: pointer;
}

.select__item.selected {
	background-color: rgba(75, 123, 236, 0.3);
	border-color: var(--main-color);
}

/*Color chooser*/

.color-chooser {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
}

.color-chooser i {
	font-size: 20px;
	cursor: pointer;
}

.color-button {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 1px solid white;
	cursor: pointer;
}

.color-button.selected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.color-button i {
	color: white;
	font-size: 14px;
}

/*Search*/

.search {
	display: flex;
	align-items: center;
	font-size: 20px;
	width: fit-content;
	min-width: 100px;
	border: 1px solid #e0e0e0;
	padding: 5px 10px;
	border-radius: 10px;
}

.search i {
	display: flex;
	justify-content: center;
	font-size: 22px;
	margin-right: 10px;
}

.search input {
	border: none;
	outline: none;
	font-size: 18px;
}

/*Link*/

.link {
	color: var(--main-color);
	cursor: pointer;
}

/*Modal*/

.modal__overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.75);
}

.modal__content {
	position: absolute;
	background: #fff;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	outline: none;
}

/*Modal form*/

.modal-form {
	display: flex;
	flex-flow: column;
	border-radius: 10px;
	border: solid 2px;
}

.modal-form__title {
	font-size: 20px;
	padding: 10px 10px 0 10px;
	color: white;
}

.modal-form__content {
	display: flex;
	flex-flow: column;
	gap: 5px;
	padding: 10px;
	color: white;
}

.modal-form__buttons {
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	background-color: white;
	padding: 5px 10px;
	border-radius: 0 0 9px 9px;
}