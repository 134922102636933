#vault {
  display: grid;
  grid-template-rows: fit-content(0) fit-content(0) auto;
  padding: 10px;
  margin-top: 48px;
  box-sizing: border-box;
}

.vault__title {
  font-size: 25px;
  color: var(--primary-text-color);
  margin-bottom: 15px;
}

.vault__buttons_wrapper {
  display: flex;
  flex-flow: row;
  gap: 15px;
  width: fit-content;
  margin-bottom: 15px;
}

.vault__cards_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 420px);
  grid-template-rows: repeat(auto-fit, 200px);
  gap: 20px;
}

.vault__card {
  background-color: var(--blue);
  width: min(380px, 100%);
  height: fit-content;
  padding: 10px;
  border-radius: 13px;
  border: solid 2px var(--blue-dark);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 25%);
  color: white;
  box-sizing: border-box;
}

.vault__card.edit {
  position: absolute;
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 100%);
}

@media only screen and (max-width: 424px) {
  .vault__cards_wrapper {
    gap: 20px 0;
  }

  .vault__card {
    width: 100%;
  }
}

.vault__card__top_bar {
  display: grid;
  grid-template-columns: auto fit-content(0) fit-content(0);
  gap: 15px;
}

.vault__card__content {
  display: flex;
  flex-flow: column;
  gap: 5px;
}

.vault__card__top_bar__title {
  font-size: 20px;
}

@media only screen and (max-width: 800px) {
  .vault__cards_wrapper {
    display: flex;
    flex-flow: column;
    gap: 20px 0;
  }

  #vault {
    margin-top: 88px !important;
  }

  .vault__card.edit {
    position: inherit !important;
  }
}